
<template>
  <div>
    <b-card no-body class="m-0">
      <b-row >
        <b-col md="6" class="d-flex mt-1">
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            v-model="per_page"
            class="per-page-selector d-inline-block ml-2 w-25"
            :options="['5', '10', '20', '50', '100']"
            @input="changePagination()"
          />
          <!-- Search -->
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-badge variant="light-primary" style="height:30px;font-size:20px" @click="handleNutritionApprove()" class="text-center titleName font-weight-bold cursor-pointer my-1 mr-2 mt-1"
            >Nutrition Request</b-badge>
        </b-col>
      </b-row>
      <div class="m-2">
        <section id="card-images" class="container1">
          <b-row
            md="12"
            style="align-item: center; justify-content: center; display: flex"
          >
            <b-table
              :busy="isLoading"
              ref="refUserListTable"
              class="position-relative"
              :items="NutriData"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
              @sort-changed="onSortChanged"
            >
              <template #table-busy>
                <div class="text-center text-secondary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(id)="data">
                <span class="text-primary font-weight-bolder">
                  {{ data.index + 1 }}
                </span>
              </template>

              <!-- <template #thead-top="{ fields }">
                <tr>
                  <th v-for="(field, index) in fields" :key="index">
                    <template v-if="field.key == 'Action'"> &nbsp; </template>
                    <template v-else-if="field.type == 'dropdown'">
                      <v-select
                        :options="unitOption"
                        :label="field.displayName"
                        v-model="columnFilters[field.key]"
                        multiple
                        @input="filterTable()"
                      />
                    </template>
                    <template v-else>
                      <b-form-input
                        v-model="columnFilters[field.key]"
                        @input="filterTable()"
                        placeholder="search..."
                      />
                    </template>
                  </th>
                </tr>
              </template> -->

              <template #cell(name)="data">
                <div class="d-flex align-items-center">
                  <span class="mr-1">
                    <template>
                      <img
                        height="60px"
                        width="60px"
                        class="mr-1 titleName"
                        :src="data && data.item ? data.item.image : ''"
                        onerror="this.src='../../../../../public//no-image.png';"
                      />
                    </template>
                    <!-- <template v-else>
                      <span>
                        <img
                          style="height: 40px; width: 40px"
                          src="../../../../../public//no-image.png"
                          alt=""
                        />
                      </span>
                    </template> -->
                  </span>

                  <span class="mr-1">
                    <span class="mr-1">
                      {{ data.item.name }}
                    </span>
                  </span>
                </div>
              </template>

              <template #cell(Quantity)="data">
                <span class="mr-1 titleName">
                  {{ data.item.quantity }}
                </span>
              </template>
              <template #cell(QTY_Measurment)="data">
                <span class="mr-1 titleName">
                  <!-- {{ data.item.unit_id }} -->
                  {{ data.item && data.item.unit ? data.item.unit.name : "" }}
                </span>
              </template>
              <template #cell(Calories)="data">
                <span class="mr-1 titleName">
                  {{ data.item.calories }}
                </span>
              </template>
              <template #cell(Carbs)="data">
                <span class="mr-1 titleName">
                  {{ data.item.carbs }}
                </span>
              </template>
              <template #cell(Protein)="data">
                <span class="mr-1 titleName">
                  {{ data.item.protein }}
                </span>
              </template>
              <template #cell(Fat)="data">
                <span class="mr-1 titleName">
                  {{ data.item.fats }}
                </span>
              </template>
              <template #cell(Action)="data">
                <div class="d-flex align-items-center">
                  <feather-icon
                    icon="EditIcon"
                    size="22"
                    class="cursor-pointer mr-1"
                    @click="handleEditForm(data.item)"
                  />
                  <feather-icon
                    icon="Trash2Icon"
                    size="22"
                    class="m-1"
                    @click="removeRow(data.item.id)"
                    style="cursor: pointer"
                  />
                  <feather-icon
                    icon="EyeIcon"
                    size="22"
                    class="m-1"
                    @click="openData(data.item)"
                    style="cursor: pointer"
                  />
                </div>
              </template>
            </b-table>
            <div class="w-100 d-flex justify-content-between p-1">
              <!-- Pagination -->
              <div>
                <span class="text-nowrap">
                  Showing {{ paginationStart }} to {{ paginationEnd }} of
                  {{ paginationTotal }}</span
                >
              </div>
              <b-pagination
                :total-rows="paginationTotal"
                pills
                :per-page="per_page"
                v-model="paginationValue"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="changePagination()"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
            <b-col class="col-md-12 d-flex d-flex justify-content-end">
              <b-button variant="primary" @click="Supplement" class="mt-2"
                >Add Nutrition
              </b-button>
            </b-col>

            <b-modal
              hide-footer
              top
              no-close-on-backdrop
              size="md"
              v-model="openModel"
            >
            <view-nutrition :selectedItem="selectedItem"></view-nutrition>
            </b-modal>
          </b-row>
        </section>
        <div class="mt-5" v-if="addNutrition" id="nutritionForm">
          <Form :selectedItem="selectedItem" :ifEdit="ifEdit" :handleCloseForm='handleCloseForm' :getNutriData="getNutriData"/>
         
          <img
            id="myImage"
            src="placeholder.jpg"
            alt="Placeholder Image"
            class="invisible"
          />
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BImg,
  BButton,
  BCard,
  BForm,
  BTable,
  BPagination,
  BFormFile,
  BFormInput,
  BFormGroup,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import axios from "@/component/axios";
import _ from "lodash";
import ViewNutrition from './View.vue';
import Form from './Form.vue'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    vSelect,
    BCardText,
    BImg,
    BCardTitle,
    BButton,
    BCard,
    BForm,
    BTable,
    BFormFile,
    BFormInput,
    BFormGroup,
    BBadge,
    ValidationProvider,
    BSpinner,
    ValidationObserver,
    ViewNutrition,
    Form
  },
  data() {
    return {
      tableColumns: [
        { key: "name", label: "Nutrition Name", sortable: true },
        { key: "quantity", label: "Quantity", sortable: true },
        { key: "unit.name",label: "QTY Measurment",type: "dropdown",displayName: "name",},
        { key: "calories", label: "Calories", sortable: true },
        { key: "carbs", label: "Carbs", sortable: true },
        { key: "protein", label: "Protein", sortable: true },
        { key: "fats", label: "Fats", sortable: true },
        { key: "Action", label: "Actions" },
      ],
      isSortDirDesc: true,
      addNutrition: false,
      required: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      clickedRowData: null,
      isSideDataLoading: false,
      nutrition: "",
      calories: "",
      carbs: "",
      protein: "",
      fats: "",
      image: "",
      openModel: false,
      NutriData: [],
      id: 0,
      isLoading: false,
      selectedItem: {},
      ifEdit: false,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      paginationStart: 0,
      paginationEnd: 0,
      selectedId: null,
      quantity: null,
      quantityMeasuerment: "",
      flag: false,
      columnFilters: {},
      params: {},
    };
  },
  mounted() {
    this.getNutriData();
  },
  methods: {
    handleCloseForm(){
      this.ifEdit=false;
      this.selectedItem={};
      this.addNutrition=false
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      this.getNutriData();
    },
    handleNutritionApprove() {
      // this.selectedId = id;
      this.$router.push({ path: "/nutrition-approval" });
    },
    changePagination() {
      this.getNutriData();
    },
    async handleEditForm(item) {
      this.addNutrition = true;
      this.ifEdit = true;
      this.selectedItem=item
      setTimeout(() => {
        const formElement = this.$refs.myForm;
        if (formElement) {
          formElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 100);
    },
    async getNutriData() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}nutrition/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
        // timeout: 1000,
      })
        .then((json) => {
          this.NutriData = json?.data.nutrition;
          this.paginationTotal = json?.data.pagination.total;
          this.paginationStart = json?.data.pagination.current_page;
          this.paginationEnd = json?.data.pagination.per_page;
          this.isLoading = false;
          this.NutriData.map((item) => {
            let img = document.getElementById("myImage");
            let testImage = new Image();
            // Set up event  listeners for the test image
            testImage.onload = function () {
              // If the image exists, update the source of the main image
              if (item.image && img) {
                img.src = item.image;
              }
            };
            testImage.onerror = function () {
              item.image = "";
            };
            if (item.image) {
              testImage.src = item.image;
            }
          });
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isLoading = false;
        });
    },
    openData(data) {
      this.openModel = true;
      this.selectedItem = data;
    },
    async removeRow(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            await axios({
              method: "Delete",
              url: `${this.baseApi}nutrition/delete/${id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then(() => {
                const index = this.NutriData.findIndex(
                  (item) => item.id === id
                );
                if (index !== -1) {
                  this.NutriData.splice(index, 1);
                  this.$swal({
                    title: "Deleted",
                    text: "Client deleted successfully",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.getNutriData();
                  this.$forceUpdate();
                }
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },
    Supplement() {
      this.ifEdit = false;
      this.addNutrition = !this.addNutrition;
      setTimeout(() => {
        const formElement = this.$refs.myForm;
        if (formElement) {
          formElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 100);
    },
  },
};
</script>
<style lang="scss">
.text-danger {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

 
<style scoped>
.titleName {
  font-family: "Public Sans", sans-serif;
}
.nutri-img{
  max-height: 300px;
  max-width: 300px
}
</style>